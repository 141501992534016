<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-finacial">
    <!-- Avatar Row -->
    <div class="vx-row mt-3">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="tabs-shadow-none"
        id="profile-tabs"
        :key="isSmallerScreen"
        v-model="tabindex"
      >
        <!-- GENERAL -->
        <vs-tab
          icon-pack="feather"
          icon="icon-info"
          :label="!isSmallerScreen ? 'Basic Info' : ''"
        >
          <div class="tab-general md:ml-4  ml-0">
            <vx-card no-shadow>
              <div class="vx-col  w-full">
                <div class=" w-full">
                  <h4 class="" style="font-size: 15px">
                    How will you be funding your studies in Australia?
                  </h4>
                  <vs-textarea v-model="textarea" class="mt-4" height="150px" />
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex justify-end">
           <!-- <vs-button size="small" type="border" class="mr-2 mt-2"  @click="UpdateUserFinancialDetails(1)"
            >Save as draft</vs-button
          > -->
          <vs-button color="primary" size="small" class="mt-2"  @click="BackCourseview(result)"
            >Back</vs-button
          >

          <vs-button size="small" color="success" class="ml-auto mt-2" @click="UpdateUserFinancialDetails(2)"
            >Save & Continue</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor
  },

  props: ['pub_userid'],

  data() {
    return {
      tabindex: "0",
      experience: "",
      discription: ``,
      textarea: "",
      experienceValid: false,
      studentid: 0
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  },
  created() {
    // this.studentid = JSON.parse(
    //   localStorage.getItem("pCoursepalUserObj")
    // ).pub_userid;
    this.studentid = this.pub_userid;
    this.UserMedicalConditionDetails();
  },
  methods: {
    async UserMedicalConditionDetails() {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("pub_userid", this.studentid);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UserFinancialDetails",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.data.length) {
        var ResponseData = response.data[0];
        this.textarea = ResponseData.financial_details;

        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    async UpdateUserFinancialDetails(type) {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("pub_userid", this.studentid);
      urlencoded.append("financial_details", this.textarea);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UpdateUserFinancialDetails",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.status == true) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Details  Updated  Successfully",
          text: response.data[0].message,
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.files = [];
        this.UserMedicalConditionDetails();
        if(type === 2){           
           setTimeout(()  => {
              this.$emit("nextScreenEvent")
           }, 1000)  
        }
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: response.data[0].message,
          text: response.data[0].message,
          color: "primary",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      }
      this.$vs.loading.close();
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    BackCourseview(){
     this.$router.push("/CourseView/:id");
    },
  }
};
</script>
<style lang="scss" scoped>
.search-location {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  height: 45px;
  line-height: 30px;
  text-align: center;
}
</style>
