<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-englishprofiency">
    <!-- Avatar Row -->
    <div class="vx-row mt-3">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="tabs-shadow-none"
        id="profile-tabs"
        :key="isSmallerScreen"
        v-model="tabindex"
      >
        <!-- GENERAL -->
        <vs-tab
          icon-pack="feather"
          icon="icon-info"
          :label="!isSmallerScreen ? 'Basic Info' : ''"
        >
          <div class="tab-general md:ml-4  ml-0">
            <vx-card no-shadow>
              <h4 class="" style="font-size: 15px">
                Have you taken OR will you be taking an English Test?
              </h4>

              <h4 class="mt-5" style="font-size: 15px">
                I will take a test on:
              </h4>
              <div class="vx-col  w-full mb-5">
                <flat-pickr class="w-full mt-4" v-model="testname" />
              </div>
              <label class="mt-5" style="font-size: 15px text-grey">Or</label>
              <h4 class="mt-5" style="font-size: 15px">
                I have completed the following test:
              </h4>

              <div class="vx-row mt-4">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="1" class="mr-8 mt-2"
                    >IELTS (academic) test
                  </vs-radio>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="2" class="mr-8"
                    >Pearson Test of English (PTE academic)</vs-radio
                  >
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="3" class="mr-8 mt-2"
                    >TOEFL iBT (internet based)</vs-radio
                  >
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="4" class="mr-8"
                    >Cambridge English Advanced test (CAE)</vs-radio
                  >
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="5" class="mr-8 mt-2">
                    Cambridge English Proficiency (CPE)</vs-radio
                  >
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="6" class="mr-8">
                    Others</vs-radio
                  >
                </div>
              </div>
              <h4 class="mt-5" style="font-size: 15px">Registration number:</h4>
              <div class="vx-col  w-full mb-5">
                <vs-input class="w-full mt-4" v-model="regnumber" />
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab
          icon-pack="feather"
          icon="icon-file-text"
          :label="!isSmallerScreen ? 'Results' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card class="mt-base" no-shadow card-border>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                    <feather-icon
                      svgClasses="w-6 h-6"
                      icon="EditIcon"
                      class="mr-2"
                    />
                    <span class="font-medium  leading-none"
                      >English Language Test Results</span
                    >
                  </div>
                  <vs-divider />
                </div>
              </div>

              <div class="block overflow-x-auto">
                <table class="w-full">
                  <tr>
                    <!--
              You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
              our data structure. You just have to loop over above variable to get table headers.
              Below we made it simple. So, everyone can understand.
             -->
                    <th
                      class="font-semibold text-base text-left px-3 py-2"
                      v-for="heading in ['Skills', 'Score']"
                      :key="heading"
                    >
                      {{ heading }}
                    </th>
                  </tr>

                  <tr>
                    <td class="px-3 py-2">Overall Score</td>
                    <td class="px-3 py-2"><vs-input v-model="Overall" /></td>
                  </tr>
                  <tr>
                    <td class="px-3 py-2">Writing</td>
                    <td class="px-3 py-2"><vs-input v-model="Writing" /></td>
                  </tr>
                  <tr>
                    <td class="px-3 py-2">Reading</td>
                    <td class="px-3 py-2"><vs-input v-model="Reading" /></td>
                  </tr>
                  <tr>
                    <td class="px-3 py-2">Listening</td>
                    <td class="px-3 py-2"><vs-input v-model="Listening" /></td>
                  </tr>
                  <tr>
                    <td class="px-3 py-2">Speaking</td>
                    <td class="px-3 py-2"><vs-input v-model="Speaking" /></td>
                  </tr>
                </table>
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab
          icon-pack="feather"
          icon="icon-file"
          :label="!isSmallerScreen ? 'Documents' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <h4 class="" style="font-size: 15px">
                Please attach copy of your test results.
              </h4>
              <div class="form-group">
              <input
                type="file"
                id="files_eventss"
                class="form-control-file my-3"
                ref="files"
                multiple
                accept=".png,.gif,.jpg,.pdf,.doc"
                v-on:change="handleFileUploads()"
              />
              </div>

              <div class="vx-row mt-5" v-if="DocumentData.length">
                <div
                  class="vx-col w-full sm:w-1/2 lg:w-1/4 mb-base application-uploads"
                  v-for="(item, index) in DocumentData"
                  :key="item.media_id"
                >
                  <vx-card
                    remove-card-action
                    @remove="closeCardAnimationDemoForPresenter(item, index)"
                  >
                    <div slot="no-body">
                      <img v-if='item.med_url.includes(".jpg")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />

                      <img v-if='item.med_url.includes(".png")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />

                      <img v-if='item.med_url.includes(".pdf")'
                        :src="require('@/assets/images/pages/pdf.png')"
                        alt=""
                        class="grid-view-img px-4"
                        width="100%"
                      />
                    </div>
                  </vx-card>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex justify-end">
           <!-- <vs-button size="small" type="border" class="mr-2 mt-2"  @click="UpdateUserEnglishProficiency(1)"
            >Save as draft</vs-button
          > -->
          <vs-button color="primary" size="small" class="mt-2"  @click="BackCourseview(result)"
            >Back</vs-button
          >

          <vs-button size="small" color="success" class="ml-auto mt-2" @click="UpdateUserEnglishProficiency(2)"
            >Save & Continue</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    quillEditor,
    vSelect,
    flatPickr,
    Datepicker
  },

  props: ['pub_userid'],

  data() {
    return {
      tabindex: "0",
      experience: "",
      discription: ``,
      testname: "",
      pathway: "",
      regnumber: "",
      Overall: "",
      Writing: "",
      Reading: "",
      Listening: "",
      Speaking: "",
      textarea: "",
      experienceValid: false,
      studentid: 0,
      files: [],
      DocumentData: [],
      DeleteImageFile: [],
      ImageFileType: []
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  },
  created() {
   // this.studentid = JSON.parse(
    //   localStorage.getItem("pCoursepalUserObj")
    // ).pub_userid;
    this.studentid = this.pub_userid;
    // this.CountryList();
    this.UserEnglishProficiency();
  },
  methods: {
    closeCardAnimationDemoForPresenter(item, index) {
      // var Row={'media_id':this.Mediadata[index].media_id,'med_url':this.Mediadata[index].med_url};
      var Row = { url: item.med_url };
      this.DeleteImageFile.push(Row);

      this.DocumentData.splice(index, 1);

      // console.log(this.DeleteFiles);
    },
    async UserEnglishProficiency() {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("pub_userid", this.studentid);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UserEnglishProficiency",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.data.length) {
        var ResponseData = response.data[0];
        this.testname = ResponseData.test_on_date;
        this.experience = ResponseData.test_completed;
        this.regnumber = ResponseData.registration_number;
        this.Overall = ResponseData.overall_score;
        this.Writing = ResponseData.writing;
        this.Reading = ResponseData.reading;
        this.Listening = ResponseData.listening;
        this.Speaking = ResponseData.speaking;

        this.DocumentData = [];
        if (ResponseData.documents.length) {
          for (let index = 0; index < ResponseData.documents.length; index++) {
            var Row = {
              media_id: ResponseData.documents[index].doc_id,
              med_url: ResponseData.documents[index].doc_name
            };
            this.DocumentData.push(Row);
          }
        }

        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    handleFileUploads() {
      this.files = [];
      this.ImageFileType = [];
      var FileData = this.$refs.files.files;
      // console.log(FileData[0].type);
      for (var i = 0; i < FileData.length; i++) {
        this.files.push(FileData[i]);
      }
    },
    async UpdateUserEnglishProficiency(type) {
      this.$vs.loading();

      const formdata = new FormData();

      if (this.files.length) {
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          console.log(file);
          formdata.append("uploads[]", file);
        }
      } else {
        formdata.append("uploads[]", []);
      }

      // formdata.append("image_upload_options",  JSON.stringify(this.ImageFileType));
      formdata.append(
        "file_delete_options",
        JSON.stringify(this.DeleteImageFile)
      );
      formdata.append("pub_userid", this.studentid);
      formdata.append("test_on_date", this.testname);
      formdata.append("test_completed", this.experience);
      formdata.append("registration_number", this.regnumber);
      formdata.append("overall_score", this.Overall);
      formdata.append("writing", this.Writing);
      formdata.append("reading", this.Reading);
      formdata.append("listening", this.Listening);
      formdata.append("speaking", this.Speaking);
      formdata.append("updated_by", this.studentid);
      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UpdateUserEnglishProficiency",
        {
          method: "POST",
          body: formdata
        }
      );
      const response = await rawResponse.json();
      this.$vs.loading.close();
      if (response.status == true) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Proficiency Updated  Successfully",
          text: response.data[0].message,
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.tabindex = "0";
        this.files = [];
        this.UserEnglishProficiency();
        if(type === 2){           
           setTimeout(()  => {
              this.$emit("nextScreenEvent")
           }, 1000)  
        }
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: response.data[0].message,
          text: response.data[0].message,
          color: "primary",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      }
      this.$vs.loading.close();
    },
    BackCourseview(){
     this.$router.push("/CourseView/:id");
    },
  }
};
</script>
<style lang="scss" scoped>
.search-location {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  height: 45px;
  line-height: 30px;
  text-align: center;
}
</style>
