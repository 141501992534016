<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-workexperince">
    <!-- Avatar Row -->
    <div class="vx-row mt-3">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="tabs-shadow-none"
        id="profile-tabs"
        :key="isSmallerScreen"
        v-model="tabindex"
      >
        <!-- GENERAL -->
        <vs-tab
          icon-pack="feather"
          icon="icon-info"
          :label="!isSmallerScreen ? 'Basic Info' : ''"
        >
          <div class="tab-general md:ml-4  ml-0">
            <vx-card no-shadow>
              <h4 class="" style="font-size: 15px">
                Do you have a disability, impairment or long-term medical
                condition which may affect your studies?
              </h4>
              <div class="vx-row mt-4">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio
                    v-model="experience"
                    vs-value="yes"
                    class="mr-8 mt-2"
                    >Yes
                  </vs-radio>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="experience" vs-value="no" class="mr-8"
                    >No</vs-radio
                  >
                </div>

                <vs-alert
                  :active="experienceValid"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-info"
                >
                  <span>Please select the disability status?</span>
                </vs-alert>
              </div>

              <div class="vx-col  w-full" v-if="experience == 'yes'">
                <div class="mt-5 w-full">
                  <label class="mb-5" style="font-size: 15px"
                    >Please provide the details:
                  </label>
                  <vs-textarea v-model="textarea" class="mt-4" height="150px" />
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab
          icon-pack="feather"
          icon="icon-file"
          :label="!isSmallerScreen ? 'Documents' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <h4 class="" style="font-size: 15px">
                Please attach additional information including details of
                support services required.
              </h4>

              <div class="form-group">
              <input
                type="file"
                id="files_eventss"
                ref="files"
                class="form-control-file my-3"
                accept=".png,.gif,.jpg,.pdf,.doc"
                multiple
                v-on:change="handleFileUploads()"
              />
              </div>

              <div class="vx-row mt-5" v-if="DocumentData.length">
                <div
                  class="vx-col w-full sm:w-1/2 lg:w-1/4 mb-base application-uploads"
                  v-for="(item, index) in DocumentData"
                  :key="item.media_id"
                >
                  <vx-card
                    remove-card-action
                    @remove="closeCardAnimationDemoForPresenter(item, index)"
                  >
                    <div slot="no-body">
                      <img v-if='item.med_url.includes(".jpg")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />
                      <img v-if='item.med_url.includes(".png")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />

                      <img v-if='item.med_url.includes(".pdf")'
                        :src="require('@/assets/images/pages/pdf.png')"
                        alt=""
                        class="grid-view-img px-4"
                        width="100%"
                      />
                    </div>
                  </vx-card>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex justify-end">
           <!-- <vs-button size="small" type="border" class="mr-2 mt-2" @click="UpdateUserWorkExperienceDetails(1)"
            >Save as draft</vs-button
          > -->
          <vs-button color="primary" size="small" class="mt-2" @click="BackCourseview(result)"
            >Back</vs-button
          >

          <vs-button size="small"
           color="success"
            class="ml-auto mt-2"
            @click="UpdateUserWorkExperienceDetails(2)"
            >Save & Continue</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor
  },

  props: ['pub_userid'],

  data() {
    return {
      tabindex: "0",
      experience: "",
      discription: ``,
      textarea: "",
      experienceValid: false,
      studentid: 0,
      files: [],
      DeleteImageFile: [],
      ImageFileType: [],
      DocumentData: []
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  },
  created() {
    // this.studentid = JSON.parse(
    //   localStorage.getItem("pCoursepalUserObj")
    // ).pub_userid;
    this.studentid = this.pub_userid;
    this.UserMedicalConditionDetails();
  },
  methods: {
    closeCardAnimationDemoForPresenter(item, index) {
      // var Row={'media_id':this.Mediadata[index].media_id,'med_url':this.Mediadata[index].med_url};
      var Row = { url: item.med_url };
      this.DeleteImageFile.push(Row);

      this.DocumentData.splice(index, 1);

      // console.log(this.DeleteFiles);
    },
    async UserMedicalConditionDetails() {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("pub_userid", this.studentid);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UserMedicalConditionDetails",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.data.length) {
        var ResponseData = response.data[0];
        this.experience = ResponseData.have_medical_condition;
        this.textarea = ResponseData.medical_condition_details;
        this.DocumentData = [];
        if (ResponseData.documents.length) {
          for (let index = 0; index < ResponseData.documents.length; index++) {
            var Row = {
              media_id: ResponseData.documents[index].doc_id,
              med_url: ResponseData.documents[index].doc_name
            };
            this.DocumentData.push(Row);
          }
        }

        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    handleFileUploads() {
      this.files = [];
      this.ImageFileType = [];
      var FileData = this.$refs.files.files;
      // console.log(FileData[0].type);
      for (var i = 0; i < FileData.length; i++) {
        this.files.push(FileData[i]);
      }

      console.log(this.ImageFileType);
    },

    async UpdateUserWorkExperienceDetails(type) {
      this.$vs.loading();

      const formdata = new FormData();

      if (this.files.length) {
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          console.log(file);
          formdata.append("uploads[]", file);
        }
      } else {
        formdata.append("uploads[]", []);
      }

      formdata.append(
        "file_delete_options",
        JSON.stringify(this.DeleteImageFile)
      );
      formdata.append("pub_userid", this.studentid);
      formdata.append("have_medical_condition", this.experience);
      formdata.append("medical_condition_details", this.textarea);
      formdata.append("updated_by", this.studentid);
      const rawResponse = await fetch(
        "https://api.coursepal.app/api/UpdateUserMedicalConditionDetails",
        {
          method: "POST",
          body: formdata
        }
      );
      const response = await rawResponse.json();
      this.$vs.loading.close();
      if (response.status == true) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Support Service Updated  Successfully",
          text: response.data[0].message,
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.tabindex = "0";
        this.files = [];
        this.UserMedicalConditionDetails();
        if(type === 2){           
           setTimeout(()  => {
              this.$emit("nextScreenEvent")
           }, 1000)  
        }
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: response.data[0].message,
          text: response.data[0].message,
          color: "primary",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      }
      this.$vs.loading.close();
    },
    BackCourseview(){
     this.$router.push("/CourseView/:id");
    },
  }
};
</script>
<style lang="scss" scoped>
.search-location {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  height: 45px;
  line-height: 30px;
  text-align: center;
}
</style>
