<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-educational">
    <!-- Avatar Row -->
    <div class="vx-row mt-3">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="tabs-shadow-none"
        id="profile-tabs"
        :key="isSmallerScreen"
        v-model="tabindex"
      >
        <!-- GENERAL -->
        <vs-tab
          icon-pack="feather"
          icon="icon-info"
          :label="!isSmallerScreen ? 'Basic Info' : ''"
        >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <h4 class="" style="font-size: 14px">
                Are you currently studying?
              </h4>
              <div class="vx-row mt-3">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="studying" vs-value="Yes" class="mr-8 mt-2"
                    >Yes
                  </vs-radio>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-radio v-model="studying" vs-value="No" class="mr-8"
                    >No</vs-radio
                  >
                </div>

                <vs-alert
                  :active="studyingValid"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-info"
                >
                  <span>Are you currently studying?</span>
                </vs-alert>
              </div>
              <div class="vx-col mt-4" v-if="studying == 'No'">
                <div class="mt-5 w-full">
                  <h4 class="mb-5">
                    Please provide details of all activities undertaken since
                    your last studies.:
                  </h4>
                  <vs-textarea v-model="details" class="mt-4" height="150px" />
                </div>
              </div>

              <div class="vx-row">
                <div
                  class="vx-col sm:w-1/2 w-full mt-5 "
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">
                    Qualification you are currently studying
                  </h4>

                  <div class="w-full mt-2">
                    <vs-input class="w-full" v-model="Qualification" />
                  </div>
                  <vs-alert
                    :active="QualificationValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please enter the Qualification</span>
                  </vs-alert>
                </div>

                <div
                  class="vx-col sm:w-1/2 w-full mt-5 "
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">Country</h4>
                  <div class="mt-2 w-full ">
                    <v-select
                      v-model="Country"
                      placeholder=""
                      :clearable="false"
                      :options="Countrys"
                      name="Country"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                  </div>
                  <vs-alert
                    :active="CountryValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please select the Country</span>
                  </vs-alert>
                </div>
                <div
                  class="vx-col sm:w-1/2 w-full mt-5 "
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">Name of school</h4>

                  <div class="w-full mt-2">
                    <vs-input class="w-full" v-model="Name" />
                  </div>
                  <vs-alert
                    :active="NameValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please enter the Name</span>
                  </vs-alert>
                </div>

                <div
                  class="vx-col sm:w-1/2 w-full mt-5 "
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">Study Mode</h4>
                  <div class="mt-2 w-full ">
                    <v-select
                      v-model="Study"
                      :clearable="false"
                      :options="Studys"
                      name="Study"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                  </div>
                  <vs-alert
                    :active="StudyValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please select the Study Method</span>
                  </vs-alert>
                </div>
                <div
                  class="vx-col sm:w-1/2 w-full mt-5"
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">Join Date</h4>

                  <div class="w-full mt-2">
                    <flat-pickr class="w-full" v-model="fromDate" />
                  </div>
                  <vs-alert
                    :active="fromDateValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please select the Join Date</span>
                  </vs-alert>
                </div>

                <div
                  class="vx-col sm:w-1/2 w-full mt-5"
                  v-if="studying == 'Yes'"
                >
                  <h4 class="" style="font-size: 14px">Completion date</h4>
                  <div class="mt-2 w-full ">
                    <flat-pickr class="w-full " v-model="Completion" />
                  </div>
                  <vs-alert
                    :active="CompletionValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please select the Completion Date</span>
                  </vs-alert>
                </div>
                <div class="vx-col  mt-5">
                  <h4 style="font-size: 14px">
                    Have you ever been excluded, dismissed or prevented from
                    continuing previous studies either in Australia or overseas?
                  </h4>
                </div>
                <div class="vx-col sm:w-1/2 w-full mt-5">
                  <div class="mt-2 w-full mt-4">
                    <vs-radio v-model="continuing" vs-value="yess" class="mr-8"
                      >Yes</vs-radio
                    >
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full mt-5">
                  <div class="mt-5 w-full mt-4">
                    <vs-radio v-model="continuing" vs-value="nos" class="mr-8 "
                      >No</vs-radio
                    >
                  </div>
                </div>
                <div class="vx-col  w-full" v-if="continuing == 'yess'">
                  <div class="mt-5 w-full">
                    <h4 class="mb-5">Please provide details</h4>
                    <vs-textarea
                      v-model="edudetails"
                      class="mt-2"
                      height="150px"
                    />
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>

        <vs-tab
          icon-pack="feather"
          icon="icon-bookmark"
          :label="!isSmallerScreen ? 'Qualification' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0  ml-0">
            <vx-card
              class="mt-base"
              no-shadow
              card-border
              title="Academic Qualifications"
            >
              <div class="vx-row">
                <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
                  <vx-card title="Qualifications 1" collapse-action>
                    <div class="px-3 py-2">
                      <label>Name of qualification </label>
                      <vs-input v-model="qualification1" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Country of institution</label>
                      <v-select
                        v-model="CountryStudied1"
                        :clearable="false"
                        :options="Countrys"
                        name="Country"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <label>Name of institution</label>
                      <vs-input v-model="institution" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Mode of study</label>
                      <v-select
                        v-model="Study1"
                        :clearable="false"
                        :options="Studys"
                        name="Study"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <vs-checkbox v-model="completed" vs-value="1"
                        >I have completed this qualification</vs-checkbox
                      >
                    </div>
                    <div class="px-3 py-2">
                      <label>Start date</label>
                      <flat-pickr class="w-full" v-model="from1" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Completion date</label>
                      <flat-pickr class="w-full" v-model="Completion1" />
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
                  <vx-card title="Qualifications 2" collapse-action>
                    <div class="px-3 py-2">
                      <label>Name of qualification </label>
                      <vs-input v-model="qualification2" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Country of institution</label>
                      <v-select
                        v-model="CountryStudied2"
                        :clearable="false"
                        :options="Countrys"
                        name="Country"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <label>Name of institution</label>
                      <vs-input v-model="institution1" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Mode of study</label>
                      <v-select
                        v-model="Study2"
                        :clearable="false"
                        :options="Studys"
                        name="Study"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <vs-checkbox v-model="completed1" vs-value="1"
                        >I have completed this qualification</vs-checkbox
                      >
                    </div>
                    <div class="px-3 py-2">
                      <label>Start date</label>
                      <flat-pickr class="w-full" v-model="from2" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Completion date</label>
                      <flat-pickr class="w-full" v-model="Completion2" />
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
                  <vx-card title="Qualifications 3" collapse-action>
                    <div class="px-3 py-2">
                      <label>Name of qualification </label>
                      <vs-input v-model="qualification3" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Country of institution</label>
                      <v-select
                        v-model="CountryStudied3"
                        :clearable="false"
                        :options="Countrys"
                        name="Country"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <label>Name of institution</label>
                      <vs-input v-model="institution2" class="w-full" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Mode of study</label>
                      <v-select
                        v-model="Study3"
                        :clearable="false"
                        :options="Studys"
                        name="Study"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                    </div>
                    <div class="px-3 py-2">
                      <vs-checkbox v-model="completed2" vs-value="1"
                        >I have completed this qualification</vs-checkbox
                      >
                    </div>
                    <div class="px-3 py-2">
                      <label>Start date</label>
                      <flat-pickr class="w-full" v-model="from3" />
                    </div>
                    <div class="px-3 py-2">
                      <label>Completion date</label>
                      <flat-pickr class="w-full" v-model="Completion3" />
                    </div>
                  </vx-card>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab
          icon-pack="feather"
          icon="icon-file"
          :label="!isSmallerScreen ? 'Documents' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <h4 class="mb-5" style="font-size: 15px">
                Please attach copy of your test results.
              </h4>

              <div class="form-group">
              <input
                type="file"
                class="form-control-file"
                id="files_eventss"
                ref="files"
                multiple
                accept=".png,image/gif,.jpg,.pdf,.doc"
                v-on:change="handleFileUploads()"
              />
              </div>
              
              <div class="vx-row mt-5" v-if="DocumentData.length">
                <div
                  class="vx-col w-full sm:w-1/2 lg:w-1/4 mb-base application-uploads"
                  v-for="(item, index) in DocumentData"
                  :key="item.media_id"
                >
                  <vx-card
                    remove-card-action
                    @remove="closeCardAnimationDemoForPresenter(item, index)"
                  >
                    <div slot="no-body">
                      <img v-if='item.med_url.includes(".jpg")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />
                      <img v-if='item.med_url.includes(".png")'
                        :src="item.med_url"
                        alt="content-img"
                        class="grid-view-img px-4"
                        width="100%"
                      />
                      <img v-if='item.med_url.includes(".pdf")'
                        :src="require('@/assets/images/pages/pdf.png')"
                        alt=""
                        class="grid-view-img px-4"
                        width="100%"
                      />
                    </div>
                  </vx-card>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex justify-end">
           <!-- <vs-button size="small" type="border" class="mr-2 mt-2" @click="UpdateEducationalDetails(1)"
            >Save as draft</vs-button
          > -->
          <vs-button color="primary" size="small" class="mt-2" @click="BackCourseview(result)"
            >Back</vs-button
          >
          <vs-button size="small" color="success" class="ml-auto mt-2" @click="UpdateEducationalDetails(2)"
            >Save & Continue</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    vSelect,
    flatPickr,

    Datepicker
  },

  props: ['pub_userid'],

  data() {
    return {
      studyingValid: false,
      QualificationValid: false,
      CountryValid: false,
      NameValid: false,
      StudyValid: false,
      fromDateValid: false,
      CompletionValid: false,
      tabindex: "0",
      studying: "",
      details: "",
      Qualification: "",
      Country: "",
      Countrys: [],
      Name: "",
      Study: "",
      Studys: [
        { label: "on campus", value: "oncampus" },
        { label: "online ", value: "online " },
        { label: "external ", value: "external " }
      ],
      fromDate: "",
      Completion: "",
      continuing: "",
      edudetails: "",
      qualification1: "",
      qualification2: "",
      qualification3: "",
      CountryStudied1: "",
      CountryStudied2: "",
      CountryStudied3: "",
      Study1: "",
      Study2: "",
      Study3: "",
      institution: "",
      institution1: "",
      institution2: "",
      completed: "",
      completed1: "",
      completed2: "",
      from1: "",
      from2: "",
      from3: "",
      Completion1: "",

      Completion2: "",
      Completion3: "",
      DocumentData: [],
      Coursecompleted: [
        { label: "Yes", value: "yes" },
        { label: "No ", value: "no " }
      ],
      studentid: 0,
      files: [],
      DeleteImageFile: [],
      ImageFileType: [],
      AllCountrysdata: ""
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  },
  created() {
    // this.studentid = JSON.parse(
    //   localStorage.getItem("pCoursepalUserObj")
    // ).pub_userid;
    this.studentid = this.pub_userid;
    this.CountryList();
    this.EducationalDetails();
  },
  methods: {
    async EducationalDetails() {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("studentid", this.studentid);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/EducationalDetails",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.data.length) {
        var ResponseData = response.data[0];
        this.studying = ResponseData.currently_study;
        this.details = ResponseData.all_details_of_study;
        this.Qualification = ResponseData.qua_cur_study;
        this.Country =
          ResponseData.country != null || ResponseData.country != ""
            ? this.getcountryCccode(ResponseData.country)
            : "";
        this.Study =
          ResponseData.study_mode != null || ResponseData.study_mode != ""
            ? this.getStatusTypes(ResponseData.study_mode)
            : "";
        this.Name = ResponseData.name_of_schl;
        this.fromDate = ResponseData.start_date;
        this.Completion = ResponseData.com_date;
        this.continuing = ResponseData.dismissed_pre_stu;
        this.edudetails = ResponseData.dismissed_pre_stu_reson;
        this.qualification1 = ResponseData.name_qua1;
        this.qualification2 = ResponseData.name_qua2;

        this.qualification3 = ResponseData.name_qua3;
        this.CountryStudied1 =
          ResponseData.country_stu1 != null || ResponseData.country_stu1 != ""
            ? this.getcountryCccode(ResponseData.country_stu1)
            : "";
        this.CountryStudied2 =
          ResponseData.country_stu2 != null || ResponseData.country_stu2 != ""
            ? this.getcountryCccode(ResponseData.country_stu2)
            : "";
        this.CountryStudied3 =
          ResponseData.country_stu3 != null || ResponseData.country_stu3 != ""
            ? this.getcountryCccode(ResponseData.country_stu3)
            : "";
        this.Study1 =
          ResponseData.study_mode1 != null || ResponseData.study_mode1 != ""
            ? this.getStatusTypes(ResponseData.study_mode1)
            : "";
        this.Study2 =
          ResponseData.study_mode2 != null || ResponseData.study_mode2 != ""
            ? this.getStatusTypes(ResponseData.study_mode2)
            : "";
        this.Study3 =
          ResponseData.study_mode3 != null || ResponseData.study_mode3 != ""
            ? this.getStatusTypes(ResponseData.study_mode3)
            : "";

        this.institution = ResponseData.name_schl1;
        this.institution1 = ResponseData.name_schl2;
        this.institution2 = ResponseData.name_schl3;
        this.completed = ResponseData.cou_comp1;
        this.completed1 = ResponseData.cou_comp2;
        this.completed2 = ResponseData.cou_comp3;
        this.from1 = ResponseData.start_date1;
        this.from2 = ResponseData.start_date2;
        this.from3 = ResponseData.start_date3;
        this.Completion1 = ResponseData.com_date1;
        this.Completion2 = ResponseData.com_date2;
        this.Completion3 = ResponseData.com_date3;

        this.DocumentData = [];
        if (response.documents.length) {
          for (let index = 0; index < response.documents.length; index++) {
            var Row = {
              media_id: response.documents[index].doc_id,
              med_url: response.documents[index].doc_name
            };
            this.DocumentData.push(Row);
          }
        }

        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    getStatusTypes(val) {
      var Row;
      switch (val) {
        case "oncampus":
          Row = { label: "on campus", value: "oncampus" };
          break;
        case "online":
          Row = { label: "online ", value: "online " };
          break;
        case "external":
          Row = { label: "external ", value: "external " };
          break;
      }
      return Row;
    },
    getcountryCccode(val) {
      for (let index = 0; index < this.AllCountrysdata.length; index++) {
        if (val == this.AllCountrysdata[index].country_id) {
          var Row = {
            label: this.AllCountrysdata[index].country_name,
            value: this.AllCountrysdata[index].country_id
          };
        }
      }

      return Row;
    },

    closeCardAnimationDemoForPresenter(item, index) {
      // var Row={'media_id':this.Mediadata[index].media_id,'med_url':this.Mediadata[index].med_url};
      var Row = { image_url: item.med_url, image_id: item.media_id };
      this.DeleteImageFile.push(Row);

      this.DocumentData.splice(index, 1);

      // console.log(this.DeleteFiles);
    },

    async CountryList() {
      //  this.$vs.loading();
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/CountryList",
        requestOptions
      );

      const response = await rawResponse.json();
      this.AllCountrysdata = response.data;
      console.log(this.AllCountrysdata);

      for (let index = 0; index < this.AllCountrysdata.length; index++) {
        var Row = {
          label: this.AllCountrysdata[index].country_name,
          value: this.AllCountrysdata[index].country_id
        };
        this.Countrys.push(Row);
        // this.productss.push(Row);
      }

      console.log(response);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    handleFileUploads() {
      this.files = [];
      this.ImageFileType = [];
      var FileData = this.$refs.files.files;
      // console.log(FileData[0].type);
      for (var i = 0; i < FileData.length; i++) {
        this.files.push(FileData[i]);
      }
      for (var j = 0; j < FileData.length; j++) {
        var RowData = {
          media_type: FileData[j].type
        };
        this.ImageFileType.push(RowData);
      }
      console.log(this.ImageFileType);
    },

    async UpdateEducationalDetails(type) {
      console.log(this.streetno);
      this.studyingValid = false;
      this.QualificationValid = false;
      this.CountryValid = false;
      this.NameValid = false;
      this.StudyValid = false;
      this.fromDateValid = false;
      this.CompletionValid = false;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      if (this.studying == null || this.studying == "") {
        this.$vs.notify({
          title: "Are you currently studying?",
          color: "primary",
          position: "top-right"
        });
        this.studyingValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.Qualification == null || this.Qualification == "")
      ) {
        this.$vs.notify({
          title: "enter the Qualification",
          color: "primary",
          position: "top-right"
        });
        this.QualificationValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.Country == null || this.Country == "")
      ) {
        this.$vs.notify({
          title: "Please select the Country",
          color: "primary",
          position: "top-right"
        });
        this.CountryValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.Name == null || this.Name == "")
      ) {
        this.$vs.notify({
          title: "Please enter the Name",
          color: "primary",
          position: "top-right"
        });
        this.NameValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.Study == null || this.Study == "")
      ) {
        this.$vs.notify({
          title: "Please select the Study Method",
          color: "primary",
          position: "top-right"
        });
        this.StudyValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.fromDate == null || this.fromDate == "")
      ) {
        this.$vs.notify({
          title: "Please select the Join Date",
          color: "primary",
          position: "top-right"
        });
        this.fromDateValid = true;
        this.tabindex = "0";
      } else if (
        this.studying == "Yes" &&
        (this.Completion == null || this.Completion == "")
      ) {
        this.$vs.notify({
          title: "Please select the Completion Date",
          color: "primary",
          position: "top-right"
        });
        this.CompletionValid = true;
        this.tabindex = "0";
      } else {
        this.$vs.loading();
        if (this.fromDate != "") {
          var Date = this.formatDate(this.fromDate);
        } else {
          Date = null;
        }
        if (this.Completion != "") {
          var CompletionDate = this.formatDate(this.Completion);
        } else {
          CompletionDate = null;
        }
        if (this.Completion1 != "") {
          var Completion1Date = this.formatDate(this.Completion1);
        } else {
          Completion1Date = null;
        }
        if (this.Completion2 != "") {
          var Completion2Date = this.formatDate(this.Completion2);
        } else {
          Completion2Date = null;
        }
        if (this.Completion3 != "") {
          var Completion3Date = this.formatDate(this.Completion3);
        } else {
          Completion3Date = null;
        }
        if (this.from1 != "") {
          var from1Date = this.formatDate(this.from1);
        } else {
          from1Date = null;
        }
        if (this.from2 != "") {
          var from2Date = this.formatDate(this.from2);
        } else {
          from2Date = null;
        }
        if (this.from3 != "") {
          var from3Date = this.formatDate(this.from3);
        } else {
          from3Date = null;
        }

        this.$vs.loading();

        const formdata = new FormData();

        if (this.files.length) {
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            console.log(file);
            formdata.append("uploads[]", file);
          }
        } else {
          formdata.append("uploads[]", []);
        }

        formdata.append(
          "image_upload_options",
          JSON.stringify(this.ImageFileType)
        );
        formdata.append(
          "image_delete_options",
          JSON.stringify(this.DeleteImageFile)
        );
        formdata.append("studentid", this.studentid);
        formdata.append("currently_study", this.studying);
        formdata.append("all_details_of_study", this.details);
        formdata.append("qua_cur_study", this.Qualification);
        formdata.append(
          "country",
          this.Country == null || this.Country == "" ? "" : this.Country.value
        );
        formdata.append(
          "study_mode",
          this.Study == null || this.Study == "" ? "" : this.Study.value
        );
        formdata.append("name_of_schl", this.Name);
        formdata.append("start_date", Date);
        formdata.append("com_date", CompletionDate);
        formdata.append("dismissed_pre_stu", this.continuing);
        formdata.append("dismissed_pre_stu_reson", this.edudetails);
        formdata.append("name_qua1", this.qualification1);
        formdata.append("name_qua2", this.qualification2);
        formdata.append("name_qua3", this.qualification3);
        formdata.append(
          "country_stu1",
          this.CountryStudied1 == null || this.CountryStudied1 == ""
            ? ""
            : this.CountryStudied1.value
        );
        formdata.append(
          "country_stu2",
          this.CountryStudied2 == null || this.CountryStudied2 == ""
            ? ""
            : this.CountryStudied2.value
        );
        formdata.append(
          "country_stu3",
          this.CountryStudied3 == null || this.CountryStudied3 == ""
            ? ""
            : this.CountryStudied3.value
        );
        formdata.append(
          "study_mode1",
          this.Study1 == null || this.Study1 == "" ? "" : this.Study1.value
        );
        formdata.append(
          "study_mode2",
          this.Study2 == null || this.Study2 == "" ? "" : this.Study2.value
        );
        formdata.append(
          "study_mode3",
          this.Study3 == null || this.Study3 == "" ? "" : this.Study3.value
        );
        formdata.append("name_schl1", this.institution);
        formdata.append("name_schl2", this.institution1);
        formdata.append("name_schl3", this.institution2);
        formdata.append("cou_comp1", this.completed);
        formdata.append("cou_comp2", this.completed1);
        formdata.append("cou_comp3", this.completed2);
        formdata.append("start_date1", from1Date);
        formdata.append("start_date2", from2Date);
        formdata.append("start_date3", from3Date);
        formdata.append("com_date1", Completion1Date);
        formdata.append("com_date2", Completion2Date);
        formdata.append("com_date3", Completion3Date);
        const rawResponse = await fetch(
          "https://api.coursepal.app/api/UpdateEducationalDetails",
          {
            method: "POST",
            body: formdata
          }
        );
        const response = await rawResponse.json();
        this.$vs.loading.close();
        if (response.status == true) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Qualification Updated  Successfully",
            text: response.data[0].message,
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
          this.files = [];
          this.tabindex = "0";
          this.EducationalDetails();
          if(type === 2){           
           setTimeout(()  => {
              this.$emit("nextScreenEvent")
           }, 1000)  
          }

          //  this.$router.push('/NonMember');
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: response.data[0].message,
            text: response.data[0].message,
            color: "primary",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }
        this.$vs.loading.close();
      }
    },

    BackCourseview(){
     this.$router.push("/CourseView/:id");
    },
    
  }
};
</script>
<style lang="scss" scoped>
.search-location {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  height: 45px;
  line-height: 30px;
  text-align: center;
}
</style>
