<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit" class="mt-3" >

    <vx-card >

      <div slot="no-body" class="tabs-container px-6 pt-6 mt-3" >

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Personal details" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <Personaldetails @nextScreenEvent="nextScreen(1)" v-if="pub_userid !== 0" v-bind:pub_userid="pub_userid" />
            </div>
          </vs-tab>
           <vs-tab label="Educational qualifications" icon-pack="feather" icon="icon-book">
            <div class="tab-text">
              <Educationalqualifications @nextScreenEvent="nextScreen(2)" v-if="pub_userid !== 0" v-bind:pub_userid="pub_userid" />
            </div>
          </vs-tab>
          <vs-tab label="Passport details" icon-pack="feather" icon="icon-flag">
            <div class="tab-text">
              <Passportandvisainformation @nextScreenEvent="nextScreen(3)" v-if="pub_userid !== 0"  v-bind:pub_userid="pub_userid"   />
            </div>
          </vs-tab>
           <vs-tab label="English proficiency" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <Englishproficiency @nextScreenEvent="nextScreen(4)" v-if="pub_userid !== 0"  v-bind:pub_userid="pub_userid"  />
            </div>
          </vs-tab>
           <vs-tab label="Financial details" icon-pack="feather" icon="icon-credit-card">
            <div class="tab-text">
              <Financialdetails @nextScreenEvent="nextScreen(5)" v-if="pub_userid !== 0"  v-bind:pub_userid="pub_userid"  />
            </div>
          </vs-tab>
          <vs-tab label="Support services" icon-pack="feather" icon="icon-plus">
            <div class="tab-text">
              <SupportServices @nextScreenEvent="nextScreen(6)" v-if="pub_userid !== 0"  v-bind:pub_userid="pub_userid"  />
            </div>
          </vs-tab>
          <vs-tab label="Work experience" icon-pack="feather" icon="icon-book-open">
            <div class="tab-text">
              <Workexperience @nextScreenEvent="nextScreen(7)" v-if="pub_userid !== 0"  v-bind:pub_userid="pub_userid"  />
            </div>
          </vs-tab>


        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import Personaldetails     from "./Personaldetails.vue"
import Passportandvisainformation from "./Passportandvisainformation.vue"
import Educationalqualifications      from "./Educationalqualifications.vue"
import Englishproficiency      from "./Englishproficiency.vue"
import Financialdetails      from "./Financialdetails.vue"
import SupportServices      from "./SupportServices.vue"
import Workexperience      from "./Workexperience.vue"
import axios from 'axios'



export default {
  components: {
    Personaldetails,
    Passportandvisainformation,
    Educationalqualifications,
    Englishproficiency,
    Financialdetails,
    SupportServices,
    Workexperience
  },
  data() {
    return {
      pub_userid: 0,
      activeTab: "0",
    }
  },
  watch: {

  },
  methods: {
    nextScreen(activeTab) {
      this.activeTab = activeTab;
    },
    getUserDetailsBasedOnUserName(){     
      this.$vs.loading();
      axios.post(`${process.env.VUE_APP_BASE_URL  }/api/users/getUserDetailsBasedOnUserName`, {
          user_name: this.$store.state.auth.userData.name
        }).then(res => {
          if (res.data.data[0] !== undefined) {
            this.pub_userid = res.data.data[0].pub_userid;
          }else{
            //Code for old user records, all new signup should have record in mysql db
             axios.post(`${process.env.VUE_APP_BASE_URL  }/api/LoginInsertion`, {
                username: this.$store.state.auth.userData.name,
                emailid: this.$store.state.auth.userData.email,
                source_signin: 'password',
                profile_pic: this.$store.state.auth.userData.photoURL,
                first_name: this.$store.state.auth.userData.displayName,
                phoneNumber: '',
                uid: ''
              }).then(res => {
                  if (res.data.data[0] !== undefined) {
                    this.pub_userid = res.data.data[0].pub_userid;
                  }
              });   
          }
      });
    }
  },
  mounted () {
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
  },
  beforeDestroy () {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  },
  created() {
    // Register Module UserManagement Module
    this.getUserDetailsBasedOnUserName();
  }
}

</script>
