<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-newaccount">
    <!-- Avatar Row -->
    <div class="vx-row mt-3">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="tabs-shadow-none"
        id="profile-tabs"
        :key="isSmallerScreen"
        v-model="tabindex"
      >
        <!-- GENERAL -->
        <vs-tab
          icon-pack="feather"
          icon="icon-info"
          :label="!isSmallerScreen ? 'Basic Info' : ''"
        >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full">
                    <h4 class="" style="font-size: 14px">
                      First Name<span class="text-primary">*</span>
                    </h4>
                    <vs-input
                      data-vv-as="field"
                      name="First Name"
                      class="w-full mt-2"
                      icon-pack="feather"
                      icon="icon-user"
                      icon-no-border
                      v-model="fname"
                    />
                    <vs-alert
                      :active="fnameValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter the first name</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full">
                    <h4 class="" style="font-size: 14px">Last Name</h4>
                    <vs-input
                      data-vv-as="field"
                      name="Last Name"
                      class="w-full mt-2"
                      icon-pack="feather"
                      icon="icon-user"
                      icon-no-border
                      v-model="lname"
                    />
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full ">
                    <h4 class="" style="font-size: 14px">
                      Date Of Birth<span class="text-primary">*</span>
                    </h4>
                    <br />
                    <flat-pickr class="w-full " v-model="fromDate" />
                    <vs-alert
                      :active="fromDateValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter date Of birth</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full mt-5">
                  <h4 class="" style="font-size: 14px">
                    Gender<span class="text-primary">*</span>
                  </h4>
                  <div class="mt-5 w-full">
                    <vs-radio v-model="gender" vs-value="Male" class="mr-8"
                      >Male</vs-radio
                    >
                    <vs-radio v-model="gender" vs-value="Female" class="mr-8"
                      >Female</vs-radio
                    >
                    <vs-radio v-model="gender" vs-value="Other">Other</vs-radio>
                    <vs-alert
                      :active="genderValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter the gender</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full">
                    <h4 class="" style="font-size: 14px">
                      Email Address<span class="text-primary">*</span>
                    </h4>
                    <vs-input
                      icon-pack="feather"
                      icon="icon-mail"
                      icon-no-border
                      data-vv-as="field"
                      name="Email Address"
                      class="w-full mt-2"
                      v-model="email_address"
                    />
                    <vs-alert
                      :active="email_addressValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter the email Address</span>
                    </vs-alert>
                    <vs-alert
                      :active="vemail_addressValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter the email Address</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full vx-row">
                  <div class="mt-5 sm:w-1/2 pl-4">
                    <h4 class="" style="font-size: 14px">
                      Country Code<span class="text-primary">*</span>
                    </h4>
                    <v-select
                      class="w-full mt-2"
                      v-model="code"
                      :clearable="false"
                      :options="countryCc"
                      name="status"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                    <vs-alert
                      :active="CountryValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Fields are manadatory!</span>
                    </vs-alert>
                  </div>

                  <div class="mt-5 w-full sm:w-1/2 pl-3">
                    <h4 class="" style="font-size: 14px">
                      Mobile Number<span class="text-primary">*</span>
                    </h4>
                    <vs-input
                      icon-pack="feather"
                      icon="icon-smartphone"
                      icon-no-border
                      data-vv-as="field"
                      name="Mobile Number"
                      class="w-full mt-2"
                      v-model="mobile"
                    />
                    <vs-alert
                      :active="mobileValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please enter the mobile number</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full">
                    <h4 class="" style="font-size: 14px">
                      Citizenship<span class="text-primary">*</span>
                    </h4>
                    <v-select
                      class="w-full mt-2"
                      v-model="Citizenship"
                      :clearable="false"
                      :options="Countrys"
                      name="Country"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                    <vs-alert
                      :active="CitizenshipValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please select the citizebship</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5 w-full">
                    <h4 class="" style="font-size: 14px">
                      Country of birth<span class="text-primary">*</span>
                    </h4>
                    <v-select
                      class="w-full mt-2"
                      v-model="birth"
                      :clearable="false"
                      :options="Countrys"
                      name="Country"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                    <vs-alert
                      :active="birthValid"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-info"
                    >
                      <span>Please select the country</span>
                    </vs-alert>
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <h4 class="" style="font-size: 14px">Marital Status</h4>
                    <v-select
                      class="w-full mt-2"
                      v-model="status"
                      :clearable="false"
                      :options="statusOptions"
                      name="status"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <h4 class="" style="font-size: 14px">
                      Date of Marriage (if applicable)
                    </h4>
                    <flat-pickr class="w-full mt-2" v-model="MarriageDate" />
                  </div>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <h4 class="" style="font-size: 14px">No. of dependent</h4>
                    <vs-input
                      class="w-full mt-2"
                      placeholder="*Dependents include spouse and/or children"
                      v-model="dependent"
                    />
                  </div>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <h4 class="" style="font-size: 14px">
                      No. of people accompaning you
                    </h4>
                    <vs-input
                      class="w-full mt-2"
                      placeholder="*Includes spouse and/or children"
                      v-model="accompaning"
                    />
                  </div>
                </div>
                <div class="vx-col  mt-5 w-full">
                  <h4 class="" style="font-size: 14px">
                    Do you have any family and/or relatives in Australia?
                  </h4>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <vs-radio v-model="continuing" vs-value="yess" class="mr-8"
                      >Yes</vs-radio
                    >
                  </div>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <div class="mt-5">
                    <vs-radio v-model="continuing" vs-value="nos" class="mr-8 "
                      >No</vs-radio
                    >
                  </div>
                </div>
                <div class="vx-col  w-full" v-if="continuing == 'yess'">
                  <div class="mt-5 w-full">
                    <label class="mb-5">Please provide details</label>
                    <vs-textarea
                      v-model="edudetails"
                      class="mt-2"
                      height="150px"
                    />
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>

        <vs-tab
          icon-pack="feather"
          icon="icon-home"
          :label="!isSmallerScreen ? 'Current Address Info' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <div class="vx-row">
                <div class="vx-col  w-full">
                  <vue-google-autocomplete
                    id="from_address1"
                    classname="w-full"
                    placeholder="Search Location"
                    v-on:placechanged="getAddressData1"
                    class="search-location"
                    v-model="addressFormat1"
                  ></vue-google-autocomplete>
                  <vs-alert
                    :active="addressValid1"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please enter the Address</span>
                  </vs-alert>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="unit_number"
                    label="Unit No:"
                    v-model="unit_no1"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Street Number"
                    label="Street Number:"
                    v-model="streetno1"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Street Name"
                    label="Street Name:"
                    v-model="streetname1"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Suburb"
                    label="Suburb:"
                    v-model="suburb1"
                    class="w-full mt-5"
                  />
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="city"
                    label="Town/City:"
                    v-model="city_name1"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="pincode"
                    label="Pincode:"
                    v-model="pin_code1"
                    class="w-full mt-5"
                  />
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="state"
                    label="State:"
                    v-model="state_name1"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Country"
                    label="Country:"
                    v-model="country_name1"
                    class="w-full mt-5"
                  />
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab
          icon-pack="feather"
          icon="icon-home"
          :label="!isSmallerScreen ? 'Permanent Address Info' : ''"
        >
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
              <div class="vx-row">
                <div class="vx-col  w-full">
                
                  <vs-alert
                    :active="addressValid"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-info"
                  >
                    <span>Please enter the Address</span>
                  </vs-alert>
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="unit_number"
                    label="Unit No:"
                    v-model="unit_no"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Street Number"
                    label="Street Number:"
                    v-model="streetno"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Street Name"
                    label="Street Name:"
                    v-model="streetname"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Suburb"
                    label="Suburb:"
                    v-model="suburb"
                    class="w-full mt-5"
                  />
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="city"
                    label="Town/City:"
                    v-model="city_name"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="pincode"
                    label="Pincode:"
                    v-model="pin_code"
                    class="w-full mt-5"
                  />
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="state"
                    label="State:"
                    v-model="state_name"
                    class="w-full mt-5"
                  />
                </div>

                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input
                    name="Country"
                    label="Country:"
                    v-model="country_name"
                    class="w-full mt-5"
                  />
                </div>
              </div>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex justify-end">
          <!-- <vs-button type="border" size="small" class="mr-2 mt-2" @click="UpdatePersonalDetails(1)"
            >Save as draft</vs-button
          > -->
          <vs-button color="primary" size="small" class="mt-2" @click="BackCourseview(result)"
            >Back</vs-button
          >
          <vs-button color="success" size="small" class=" ml-auto mt-2" @click="UpdatePersonalDetails(2)"
            >Save & Continue</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    flatPickr,
    quillEditor,
    Datepicker,
    VueGoogleAutocomplete
  },

  props: ['pub_userid'],

  data() {
    return {
      tabindex: "0",
      fname: "",
      lname: "",
      gender: "",
      addressValid: false,
      addressValid1: false,
      fnameValid: false,
      code: "",
      lnameValid: false,
      CitizenshipValid: false,
      CountryValid: false,
      birthValid: false,
      fromDateValid: false,
      genderValid: false,
      email_addressValid: false,
      vemail_addressValid: false,
      mobileValid: false,
      passwordValid: false,
      cpasswordValid: false,
      notcpasswordValid: false,
      Citizenship: "",
      birth: "",
      MarriageDate: "",
      dependent: "",
      accompaning: "",
      continuing: "",
      edudetails: "",

      fromDate: "",
      countryCc: [],
      Countrys: [],
      mobile: "",
      status: null,
      email_address: "",
      company_name: "",
      Account_type: "Non-Member",
      Account_typess: [{ label: "Non-Member", value: "1" }],
      password: "",
      repassword: "",
      statusOptions: [
        { label: "Married", value: "Married" },
        { label: "Widowed", value: "Widowed" },
        { label: "Separated", value: "Separated" },
        { label: "Divorced", value: "Divorced" },
        { label: "Single", value: "Single" }
      ],

      unit_no: "",
      streetno: "",
      streetname: "",
      suburb: "",
      webinar_link: "",
      city_name: "",
      pin_code: "",
      state_name: "",
      country_name: "",
      addressFormat: "",
      unit_no1: "",
      streetno1: "",
      streetname1: "",
      suburb1: "",
      webinar_link1: "",
      city_name1: "",
      pin_code1: "",
      state_name1: "",
      country_name1: "",
      addressFormat1: "",
      studentid: 0,
      AllCountrysdata: ""
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  },
  created() {
    // this.studentid = JSON.parse(
    //   localStorage.getItem("pCoursepalUserObj")
    // ).pub_userid;
    this.studentid = this.pub_userid;
    this.CountryList();
    this.PersonalDetails();
  },
  methods: {
    async PersonalDetails() {
      this.$vs.loading();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("studentid", this.studentid);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/PersonalDetails",
        requestOptions
      );

      const response = await rawResponse.json();

      if (response.data.length) {
        var ResponseData = response.data[0];
        this.fname = ResponseData.first_name;
        this.lname = ResponseData.last_name;
        this.fromDate = ResponseData.dob;
        this.gender = ResponseData.gender;
        this.email_address = ResponseData.email_id;
        this.code = this.getcountryCccode(ResponseData.country_code);
        this.mobile = ResponseData.phne_num;
        this.Citizenship = this.getcountryRow(ResponseData.citizenship);
        this.birth = this.getcountryRow(ResponseData.country_of_birth);
        this.status = this.getStatusTypes(ResponseData.is_mard);
        this.MarriageDate = ResponseData.date_mrg;
        this.dependent = ResponseData.dependents;
        this.accompaning = ResponseData.accompany_aus;
        this.continuing = ResponseData.fam_in_aus;
        this.edudetails = ResponseData.fam_in_aus_details;
        this.unit_no = ResponseData.curnt_unit_no;
        this.streetno = ResponseData.curnt_street_no;
        this.streetname = ResponseData.curnt_street_name;
        this.suburb = ResponseData.curnt_suburb;
        this.addressFormat = ResponseData.curnt_address;
        this.city_name = ResponseData.curnt_town;
        this.pin_code = ResponseData.curnt_pincode;
        this.state_name = ResponseData.curnt_state;
        this.country_name = ResponseData.curnt_cntry;
        this.unit_no1 = ResponseData.per_unit_no;
        this.streetno1 = ResponseData.per_street_no;
        this.streetname1 = ResponseData.per_street_name;
        this.suburb1 = ResponseData.per_suburb;
        this.addressFormat1 = ResponseData.per_address;
        this.city_name1 = ResponseData.per_town;
        this.pin_code1 = ResponseData.per_pincode;
        this.state_name1 = ResponseData.per_state;
        this.country_name1 = ResponseData.per_country;

        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },

    async CountryList() {
      //  this.$vs.loading();
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/CountryList",
        requestOptions
      );

      const response = await rawResponse.json();
      this.AllCountrysdata = response.data;
      console.log(this.AllCountrysdata);

      for (let index = 0; index < this.AllCountrysdata.length; index++) {
        var Row = {
          label: this.AllCountrysdata[index].country_name,
          value: this.AllCountrysdata[index].country_id
        };
        var Row1 = {
          label:
            "(" +
            this.AllCountrysdata[index].country_code +
            ")" +
            this.AllCountrysdata[index].country_name,
          value: this.AllCountrysdata[index].country_id
        };
        this.Countrys.push(Row);
        this.countryCc.push(Row1);
        // this.productss.push(Row);
      }

      console.log(response);
    },
    getcountryCccode(val) {
      for (let index = 0; index < this.AllCountrysdata.length; index++) {
        if (val == this.AllCountrysdata[index].country_id) {
          var Row1 = {
            label:
              "(" +
              this.AllCountrysdata[index].country_code +
              ")" +
              this.AllCountrysdata[index].country_name,
            value: this.AllCountrysdata[index].country_id
          };
        }
      }

      return Row1;
    },
    getStatusTypes(val) {
      var Row;
      switch (val) {
        case "Married":
          Row = { label: "Married", value: "Married" };
          break;
        case "Widowed":
          Row = { label: "Widowed", value: "Widowed" };
          break;
        case "Separated":
          Row = { label: "Separated", value: "Separated" };
          break;
        case "Divorced":
          Row = { label: "Divorced", value: "Divorced" };
          break;
        case "Single":
          Row = { label: "Single", value: "Single" };
          break;
      }
      return Row;
    },
    getcountryRow(val) {
      for (let index = 0; index < this.AllCountrysdata.length; index++) {
        if (val == this.AllCountrysdata[index].country_id) {
          var Row = {
            label: this.AllCountrysdata[index].country_name,
            value: this.AllCountrysdata[index].country_id
          };
        }
      }

      return Row;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    getAddressData: function(addressData, placeResultData, id) {
      if (addressData.street_number === "undefined") {
        this.streetno = null;
      } else {
        this.streetno = addressData.street_number;
      }
      console.log(addressData);
      this.address = addressData;

      this.streetname = addressData.route;
      this.suburb = addressData.locality;
      this.pin_code = addressData.postal_code;
      this.state_name = addressData.administrative_area_level_1;
      this.country_name = addressData.country;
      console.log(addressData);
      console.log(id);
      console.log(placeResultData);
      this.addressFormat = placeResultData.formatted_address;
    },
    getAddressData1: function(addressData, placeResultData, id) {
      if (addressData.street_number === "undefined") {
        this.streetno1 = null;
      } else {
        this.streetno1 = addressData.street_number;
      }
      console.log(addressData);
      this.address1 = addressData;

      this.streetname1 = addressData.route;
      this.suburb1 = addressData.locality;
      this.pin_code1 = addressData.postal_code;
      this.state_name1 = addressData.administrative_area_level_1;
      this.country_name1 = addressData.country;
      console.log(addressData);
      console.log(id);
      console.log(placeResultData);
      this.addressFormat1 = placeResultData.formatted_address;
    },
    cancel() {
      this.$router.push("/dashboard/analytics");
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    async UpdatePersonalDetails(type) {
      this.fnameValid = false;
      this.fromDateValid = false;
      this.genderValid = false;
      this.email_addressValid = false;
      this.CountryValid = false;
      this.mobileValid = false;
      this.CitizenshipValid = false;
      this.birthValid = false;
      this.addressValid = false;
      this.addressValid1 = false;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      if (this.fname == null || this.fname == "") {
        this.$vs.notify({
          title: "Please enter the first name",
          color: "primary",
          position: "top-right"
        });
        this.fnameValid = true;
        this.tabindex = "0";
      } else if (this.fromDate == null || this.fromDate == "") {
        this.$vs.notify({
          title: "Please enter the date of birth",
          color: "primary",
          position: "top-right"
        });
        this.fromDateValid = true;
        this.tabindex = "0";
      } else if (this.gender == null || this.gender == "") {
        this.$vs.notify({
          title: "Please enter the gender",
          color: "primary",
          position: "top-right"
        });
        this.genderValid = true;
        this.tabindex = "0";
      } else if (this.email_address == null || this.email_address == "") {
        this.$vs.notify({
          title: "Please enter the email address",
          color: "primary",
          position: "top-right"
        });
        this.email_addressValid = true;
        this.tabindex = "0";
      } else if (this.validateEmail(this.email_address) != true) {
        this.$vs.notify({
          title: "Please enter the valid email address",
          color: "primary",
          position: "top-right"
        });
        this.email_addressValid = true;
        this.tabindex = "0";
      } else if (this.code == null || this.code == "") {
        this.$vs.notify({
          title: "Please select the country code",
          color: "primary",
          position: "top-right"
        });
        this.CountryValid = true;
        this.tabindex = "0";
      } else if (this.mobile == null || this.mobile == "") {
        this.$vs.notify({
          title: "Please enter the mobile number",
          color: "primary",
          position: "top-right"
        });
        this.mobileValid = true;
        this.tabindex = "0";
      } else if (this.Citizenship == null || this.Citizenship == "") {
        this.$vs.notify({
          title: "Please select the Citizenship",
          color: "primary",
          position: "top-right"
        });
        this.CitizenshipValid = true;
        this.tabindex = "0";
      } else if (this.birth == null || this.birth == "") {
        this.$vs.notify({
          title: "Please select the country",
          color: "primary",
          position: "top-right"
        });
        this.birthValid = true;
        this.tabindex = "0";
      } else if (this.addressFormat != this.addressFormat) {
        this.$vs.notify({
          title: "Please enter the address",
          color: "primary",
          position: "top-right"
        });
        this.addressValid = true;
        this.tabindex = "1";
      } else if (this.addressFormat1 == "" || this.addressFormat1 == null) {
        this.$vs.notify({
          title: "Please enter the address",
          color: "primary",
          position: "top-right"
        });
        this.addressValid1 = true;
        this.tabindex = "2";
      } else {
        this.$vs.loading();
        if (this.fromDate != "") {
          var Date = this.formatDate(this.fromDate);
        } else {
          Date = null;
        }
        if (this.MarriageDate != "") {
          var Date1 = this.formatDate(this.MarriageDate);
        } else {
          Date1 = null;
        }

        this.$vs.loading();

        var urlencoded = new URLSearchParams();
        urlencoded.append("studentid", this.studentid);
        urlencoded.append("first_name", this.fname);
        urlencoded.append("last_name", this.lname);
        urlencoded.append("dob", Date);
        urlencoded.append("gender", this.gender);
        urlencoded.append("email_id", this.email_address);
        urlencoded.append("country_code", this.code.value);
        urlencoded.append("phne_num", this.mobile);
        urlencoded.append("citizenship", this.Citizenship.value);
        urlencoded.append("country_of_birth", this.birth.value);
        urlencoded.append("is_mard", this.status.value);
        urlencoded.append("date_mrg", Date1);
        urlencoded.append("dependents", this.dependent);
        urlencoded.append("accompany_aus", this.accompaning);
        urlencoded.append("fam_in_aus", this.continuing);
        urlencoded.append("fam_in_aus_details", this.edudetails);
        urlencoded.append("curnt_unit_no", this.unit_no);
        urlencoded.append(
          "curnt_street_no",
          this.streetno == undefined ? null : this.streetno
        );
        urlencoded.append("curnt_street_name", this.streetname);
        urlencoded.append("curnt_suburb", this.suburb);
        urlencoded.append("curnt_address", this.addressFormat);
        urlencoded.append("curnt_town", this.city_name);
        urlencoded.append("curnt_pincode", this.pin_code);
        urlencoded.append("curnt_state", this.state_name);
        urlencoded.append("curnt_cntry", this.country_name);
        urlencoded.append("per_unit_no", this.unit_no1);
        urlencoded.append(
          "per_street_no",
          this.streetno1 == undefined ? null : this.streetno1
        );
        urlencoded.append("per_street_name", this.streetname1);
        urlencoded.append("per_suburb", this.suburb1);
        urlencoded.append("per_address", this.addressFormat1);
        urlencoded.append("per_town", this.city_name1);
        urlencoded.append("per_pincode", this.pin_code1);
        urlencoded.append("per_state", this.state_name1);
        urlencoded.append("per_country", this.country_name1);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };

        const rawResponse = await fetch(
          "https://api.coursepal.app/api/UpdatePersonalDetails",
          requestOptions
        );

        const response = await rawResponse.json();
        this.$vs.loading.close();
        if (response.data[0].status == 1) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Details Updated  Successfully",
            text: response.data[0].message,
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
          this.PersonalDetails();

         if(type === 2){           
           setTimeout(()  => {
              this.$emit("nextScreenEvent")
           }, 1000)  
         }
          //  this.$router.push('/NonMember');
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: response.data[0].message,
            text: response.data[0].message,
            color: "primary",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }
        this.$vs.loading.close();
      }
    },
    BackCourseview(){
     this.$router.push("/CourseView/:id");
    },
  }
};
</script>
<style lang="scss" scoped>
.search-location {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  height: 45px;
  line-height: 30px;
  text-align: center;
}
</style>
